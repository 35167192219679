import React from 'react'
import Layout from '../../components/Layout'

function Search() {
  return (
    <Layout>

<div>
      <span>Search</span>
    </div>
    </Layout>

  )
}

export default Search
